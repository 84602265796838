@import "../../assets/styles/mixins";
.leaflet-popup-content {
    width: 150px;

    img {
        display: block;
        max-width: 100%;
    }
}

.trainBigImage {
    @include absolutely-centered;
    z-index: 400; // 為了蓋過 leaflet 的 map

    width: 50vw;
    padding: 16px;

    text-align: right;
    font-size: 24px;

    background: #fff;
    border-radius: 16px;

    transition: all 1s ease-in-out;
    svg {
        display: block;
        margin-left: auto;
    }
    img {
        display: block;
        max-width: 100%;
        border-radius: 16px;
    }
    @media screen and (min-width: 700px) {
        width: 40vw;
    }
    @media screen and (min-width: 1000px) {
        width: 60vw;
    }
}
