@import "../../../assets/styles/variables.scss";
@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/button.scss";

.modal {
    @include absolutely-centered;
    width: 50%;
    padding: 32px;
    text-align: center;
    background: $brown-100;
    box-shadow: 24px;
    border-radius: 8px;
}

// ResetGameModal styles
.buttonsBox {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;

    & button {
        flex: 1;
        &:first-child {
            border: 1px solid $brown-500;
            color: $brown-500;
            background: transparent;
            &:hover {
                background: transparent;
            }
        }
        &:last-child {
            background: $brown-500;
            color: $brown-100;
            &:hover {
                background: $brown-500;
            }
        }
    }
}

.title {
    color: $gray-900;
}

.text {
    margin-top: 16px;
    color: $gray-600;
}

.resetBtn {
    &:global(.MuiButton-textPrimary) {
        display: block;
        padding: 0;
        margin: 0 auto;
        font-size: 16px;
        font-weight: normal;
        font-family: $font-family-sans;
        line-height: 1.2;
        color: $gray-900;
    }
}
