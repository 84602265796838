@import "../../assets/styles/variables";
.card {
    border-radius: 10px;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 100px;
    height: 100px;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: white;

    // 限制輪播圖大小
    & img {
        width: 510px;
    }
}
.prevBtn,
.nextBtn {
    width: 50px;
    height: 50px;
    padding: 7px;
    margin: 10px;
    color: $brown-500;
    border: transparent;
    border-radius: 500px;
    background-color: $brown-100;
    opacity: 0.7;
}
.main-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    & .buttons {
        z-index: 3;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
}
.wrapper {
    display: flex;
    gap: 150px;
    margin: 20px;
}
