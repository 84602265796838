@import "../../../assets/styles/_variables";
@import "../../../assets/styles/_mixins";

// .backgroundImage {
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     background-image: url("../../../assets/images/bg_train_list.webp");
//     background-repeat: no-repeat;
//     background-size: cover;
// }

.mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgb(0, 0, 0, 0.1);
}

.bgTrainList {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    right: 0;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.gameContainer {
    height: 100dvh;
}

.container {
    // 因為 header 是 position: fixed，為了不要重疊，所以 pt68px
    padding-top: 68px;
}

.topButtonsBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.heading {
    &:global(.MuiTypography-h4) {
        margin-top: 24px;
        text-align: center;
        color: $gray-200;
    }
}

.trainListBox {
    margin: 40px 0 40px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
}

.bottomButtonsBox {
    @include flexbox-centered;
    flex-direction: column;

    .bottomBtn {
        width: 100%;
        margin: 8px 0;
        padding: 0;

        color: $gray-200;
    }
}

.modal {
    @include absolutely-centered;
    z-index: 12;

    padding: 16px;

    text-align: center;

    background: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 2px 6px #ccc;

    & > button {
        width: 50%;
        padding: 4px 8px;
        margin: 0 auto;
        color: #252525;
        border: 1px solid rgb(37, 37, 37, 0.8);

        background: transparent;
        border-radius: 4px;
    }
}

.modalOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;

    background: rgb(37, 37, 37, 0.5);
}
