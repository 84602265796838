@import "../../../../assets/styles/_variables";
@import "../../../../assets/styles/_mixins";

$content-size: 100%;

.logoBox {
    text-align: center;
    & > img {
        width: 300px;
    }
}

.container {
    &:global(.MuiContainer-root) {
        padding: 16px 24px;
    }
    &.bg {
        min-height: 100dvh;
        background-image: url("../../../../assets/images/tutorial_bg.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .tutorialBox {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;

        svg {
            position: absolute;
            top: 48px;
            right: 48px;

            font-size: 48px;
            color: $brown-500;
        }

        img {
            max-width: 100%;
        }
    }
}

.content {
    display: flex;
    align-items: end;
    gap: 24px;
    padding: 0 78px;

    color: $brown-100;

    & > div > h3 {
        font-size: 24px;
    }
}

.btnBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin: auto 0;
    text-align: center;
    .signBtn {
        @include transitioned;
        &:global(.MuiButton-containedPrimary) {
            width: 160px;
            font-weight: 700;
            color: $brown-500;
            background: $brown-100;
        }
    }
    & > img {
        max-width: 100%;
        width: 152px;
        height: 152px;
        object-fit: cover;
    }
}

.tutorialBtn {
    position: fixed;
    bottom: 16px;

    padding: 12px;

    color: $brown-500;

    width: 64px;
    height: 64px;
    background: #fff;
    border-radius: 50%;
    border: 0;

    & > svg {
        display: block;
        margin: 0 auto;
        font-size: 36px;
        color: #252525;
    }
}

.modalBox {
    opacity: 0;
    z-index: -999;
    transition: all 0.5s ease-in-out;
}

.modal {
    @include absolutely-centered;
    @include animate(toggle-modal, 0.5s, forwards, 1);
    @include keyframes(toggle-modal) {
        0% {
            transform: scale(0) translate(-100%, 40%);
        }
        100% {
            transform: scale(1) translate(-50%, -50%);
        }
    }
    position: fixed;
    z-index: 420; //為了蓋過 overlay
    padding: 16px;

    text-align: center;

    background: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 2px 6px #ccc;
    transform-origin: -100% 100%;

    & > h2 {
        display: flex;
        align-items: center;
        & > span {
            flex: 1;
        }
    }

    h3 {
        margin: 0;
    }

    & > button {
        width: 50%;
        padding: 4px 8px;
        margin: 8px 0 auto;
        color: #252525;
        border: 1px solid rgb(37, 37, 37, 0.8);

        background: transparent;
        border-radius: 4px;
    }

    &.modalFold {
        @include animate(modal-close, 0.5s, forwards, 1);
        @include keyframes(modal-close) {
            0% {
                transform: scale(1) translate(-50%, -50%);
            }
            100% {
                transform: scale(0) translate(-100%, 40%);
            }
        }
    }

    img {
        max-width: 100%;
    }
}

.modalOverlay {
    @include animate(toggle-overlay, 0.8s, forwards, 1);
    @include keyframes(toggle-overlay) {
        0% {
            background-color: transparent;
        }
        100% {
            background-color: rgb(37, 37, 37, 0.5);
        }
    }
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 410; // 為了蓋過全螢幕按鈕

    &.hide {
        z-index: -999;
    }
}

.overflow {
    overflow: auto;

    h3 {
        text-align: center;

        &:not(:first-child) {
            border-top: 1px solid #252525;
            padding-top: 16px;
        }
    }

    img {
        display: block;
        margin-top: 4px;
    }
    p {
        text-align: left;
    }
}
