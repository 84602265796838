.header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 16px 0;

    text-align: end;
    transition: all 2s ease-in-out;
}

.fullWidth {
    width: 100%;
}
