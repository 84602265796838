body {
    background: $body-bg;
}

h1,
h2 {
    line-height: 1.5;
    margin: 0.75em 0;
    font-family: $heading-family;

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    font-size: 2.5rem;
}

hgroup {
    h1,
    h2 {
        &:not(:last-child) {
            margin-bottom: 0;
        }
        margin-top: 0;
    }
}

.container {
    max-width: $container-width;
    margin: 0 auto;

    @media screen and (max-width: $container-width) {
        padding: 0 1.25rem;
    }

    @media screen and (min-width: #{$container-width + 1}) {
        width: $container-width;
    }
}

a {
    color: $link-color;
    text-decoration: none;
}

code {
    font-family: $font-family-monospace;
}

:not(pre) > code {
    white-space: normal;
    font-size: 0.925em;
    background: $code-background;
    padding: 0.1em 0.25em;
    display: inline-block;
}
