.button {
    @include transitioned;
    padding: 0.4em 1em;
    display: inline-block;
    border-radius: 0.25em;

    &.is-primary {
        background: $gray-200;
        border: 1px solid transparentize(darken($gray-200, 8%), 0.5);
        &:hover {
            background: lighten($gray-200, 5%);
        }
    }

    &.is-cta {
        background: $header-cta-bg;
        color: white;
        font-size: 1.4rem;
    }
}
