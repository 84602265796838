@import "./_rem_calc.scss";

$font-family-custom: "Fira Sans", "Nunito";
$font-family-system: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$font-family-cjk: Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji, PingFang TC, Hiragino Sans GB W3, Noto Sans CJK TC,
    Source Han Sans TW, Source Han Sans SC, Droid Sans Fallback, Microsoft Yahei,
    Microsoft JhengHei, \5fae软雅黑, Noto Sans TC, STXihei, \534e文细黑;
$font-family-sans: $font-family-custom, $font-family-system, $font-family-cjk;
$font-decorative: "Palanquin Dark", sans-serif;
$heading-family: $font-family-sans;

$font-family-monospace: Fira Code, Menlo, Consolas, Monaco, "Andale Mono",
    "Ubuntu Mono", monospace;

$red: #ff3333;
$blue: #2081e2;
$dark-brown: #96694b;
$light-orange: #e7ab6e;
$orange: #ec7700;
$body-bg: #fff2e3;
$font-primary-color: #525252;
$dark-green: #2c3423;
$thin-green: #60926a;
$light-green: #d2ffda;
$light-yellow: #fff7ee;

$pink: #cf9a9a;

// colors
$gray-100: #efefef;
$gray-200: #e6e6e6;
$gray-300: #b5b5b5;
$gray-600: #7d7d7d;
$gray-700: #636363;
$gray-900: #0e0e0e;

$tan-700: #6f6955;

$brown-100: #f2e5d2;
$brown-300: #d2b88f;
$brown-500: #8c6a3b;

$thin-border: 1px solid $gray-100;

$header-height: rem-calc(60);
$header-bg: transparentize(white, 0.15);
$header-border-bottom: $thin-border;
$header-cta-bg: #893636;

$footer-gradient-height: 30rem;
$hero-height: rem-calc(460);

$container-width: rem-calc(1000);

$primary: #2ecc71;
$link-color: darken($primary, 20%);

$background: $brown-500;

$intro-height: 50rem;
$intro-mobile-height: 100rem;
$feature-height: 60rem;
$feature-mobile-height: 80rem;
$roadmap-height: 40rem;
$teams-height: 50rem;
$teams-tablet-height: 100rem;
$tokenomics-height: 50rem;

$footer-bg: darken($primary, 32%);
$footer-fg: #333;
$footer-height: rem-calc(650);
$footer-subfooter-bg: lighten($footer-bg, 3%);
$footer-subfooter-height: 50px;

$marketplace-grid-card-min-width: rem-calc(200);
$marketplace-item-border: 3px solid $light-orange;
$marketplace-card-bg: #fff9ed;
$marketplace-card-width: rem-calc(300);

$documentation-toc-item-height: rem-calc(42);
$documentation-toc-bg: rgb(245, 247, 249);
$documentation-toc-width: rem-calc(300);
$documentation-toc-hover-bg: darken($documentation-toc-bg, 5%);

$code-background: #f2f2f2;

$element-colors: (
    "wood": #b7c730,
    "fire": #db4e4b,
    "water": #70c6f5,
    "earth": #ffd02b,
);
$element-badge-size: rem-calc(37);
$element-badge-size-mobile: rem-calc(30);

$breakpoints: (
    mobile: 500,
    tablet-sm: 768,
    tablet: 1024,
    desktop: 1360,
);

$hamburger-bg: #9c5a2e;
$hamburger-active-marker-color: #d6824a;
$hamburger-layer-color: white;
$hamburger-hover-opacity: 1;

$white: #ffffff;
$team-member-grid-card-min-width: rem-calc(200);
$team-member-item-border: 2px solid #e5e5e5;
$team-member-card-bg: rgba(255, 255, 255, 0.2);
$team-member-card-width: rem-calc(300);

$staking-grid-card-min-width: rem-calc(300);
