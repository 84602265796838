@import "../../assets/styles/variables";

.hamburger {
    &:global(.MuiButton-outlinedPrimary) {
        width: 68px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        color: $brown-500;
        border-color: transparent;
        border: 0;
        &:hover {
            border: 0;
        }
    }

    & .topLine,
    & .midLine,
    & .btmLine {
        width: 100%;
        border: 2px solid $brown-500;
    }
}

nav {
    display: flex;
    align-items: center;
    background-color: $brown-300;
    text-align: center;

    div {
        flex: 1;
    }

    div:nth-child(2),
    div:nth-child(4) {
        border: solid $brown-500;
        border-width: 0 1px;
    }

    div.textSpace {
        letter-spacing: 4ch;
        text-indent: 4ch;

        @media screen and (max-width: 750px) {
            letter-spacing: 2ch;
            text-indent: 2ch;
        }
    }
}
