.fullscreenBtn {
    &:global(.MuiButton-textPrimary) {
        position: fixed;
        right: 30px;
        bottom: 16px;
        z-index: 400; // 為了配合地圖頁面，Leaflet 產生的地圖要 z400 才蓋得過去
        padding: 0;

        height: 64px;

        color: #000;

        background: #fff;

        border-radius: 50%;
    }
}
