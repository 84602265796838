@import "../assets/styles/mixins";

.bgTrain {
    max-width: 100%;
    height: 100dvh;
    object-fit: cover;
}

.loginBtn {
    position: absolute;
    right: 10%;
    bottom: 10%;

    color: #e5c47b;
    text-transform: capitalize;

    border: 2px solid #e5c47b;
    padding: 8px 24px;
    text-align: center;
}

.modal {
    @include absolutely-centered;
    z-index: 2;

    padding: 16px;

    text-align: center;

    background: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 2px 6px #ccc;

    & > button {
        width: 50%;
        padding: 4px 8px;
        margin: 0 auto;
        color: #252525;
        border: 1px solid rgb(37, 37, 37, 0.8);

        background: transparent;
        border-radius: 4px;
    }
}

.modalOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    background: rgb(37, 37, 37, 0.5);
}

.hide {
    display: none;
}
