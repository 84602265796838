// 使用 Sass 的 map 模組
@use "sass:map";

@mixin absolutely-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin flexbox-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin transitioned {
    transition: 0.15s ease-in-out;
}

@mixin visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    overflow: hidden;
    position: absolute;
    height: 0;
}

@mixin rwd-gt($name) {
    @if not map.has-key($breakpoints, $name) {
        @error "#{$name} is not a known breakpoint.";
    }
    @media screen and (min-width: rem-calc(map.get($breakpoints, $name) + 1)) {
        @content;
    }
}

@mixin rwd-gte($name) {
    @if not map.has-key($breakpoints, $name) {
        @error "#{$name} is not a known breakpoint.";
    }
    @media screen and (min-width: rem-calc(map.get($breakpoints, $name))) {
        @content;
    }
}

@mixin rwd-lt($name) {
    @if not map.has-key($breakpoints, $name) {
        @error "#{$name} is not a known breakpoint.";
    }
    @media screen and (max-width: rem-calc(map.get($breakpoints, $name) - 1)) {
        @content;
    }
}

@mixin rwd-lte($name) {
    @if not map.has-key($breakpoints, $name) {
        @error "#{$name} is not a known breakpoint.";
    }
    @media screen and (max-width: rem-calc(map.get($breakpoints, $name))) {
        @content;
    }
}

@mixin hamburger {
    @include rwd-lt(tablet) {
        @content;
    }
}

@mixin no-hamburger {
    @include rwd-gte(tablet) {
        @content;
    }
}

@mixin animate($animation, $duration, $method, $times) {
    animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
    @keyframes #{$name} {
        @content;
    }
}
