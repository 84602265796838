@import "variables";
@import "mixins";
@import "base";
@import "button";

html,
body,
#root {
    padding: 0;
    margin: 0;
    font-family: $font-family-sans;
    background: $background;
    box-sizing: border-box;
}

#root {
    overflow: hidden;
}
