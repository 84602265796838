@import "../../assets/styles/variables";

.container {
    // 因為 header 是 position: fixed，為了不要重疊，所以 pt68px
    padding-top: 68px;
}

.map {
    height: 80dvh;
}

.heading {
    &:global(.MuiTypography-h4) {
        margin-top: 24px;
        text-align: center;
        color: $gray-200;
    }
}

.navBtn {
    position: absolute;
    top: 80px;
    left: 10px;
    z-index: 1000;

    width: 34px;
    height: 34px;
    padding: 2px;

    background-color: #fff;

    border: 2px solid rgb(0, 0, 0, 0.3);
    border-radius: 4px;

    &:focus {
        color: #ffa500;
    }
}
