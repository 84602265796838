@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.container {
    // 因為 header 是 position: fixed，為了不要重疊，所以 pt68px
    &:global(.MuiContainer-root) {
        padding-top: 68px;

        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        gap: 16px;
    }

    .collectionInfo {
        grid-column: span 6 / span 6;

        background: transparent;
        perspective: 1000px;
        .cardFlip {
            @include keyframes(flip-card) {
                0% {
                    box-shadow: none;
                }
                60% {
                    transform: rotateY(0);
                }
                100% {
                    transform: rotateY(180deg);
                    box-shadow: 0 0 10px 10px #fff;
                }
            }
            @include animate(flip-card, 2s, forwards, 1);
        }

        .flipCard {
            position: relative;
            height: 100%;
            text-align: center;
            transition: transform 0.6s;
            transform-style: preserve-3d;

            .flipCardFront,
            .flipCardBack {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                display: flex;

                background: transparent;

                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                img {
                    max-width: 100%;
                    margin: 0 auto;
                }
            }
            .flipCardFront {
                // 一開始就轉 180 度等於卡片正面設定在背景面，透過動畫轉 180 度，剛好可以把卡片正面轉回來
                transform: rotateY(180deg);
            }
        }
    }

    .collections {
        // 因為要預留位置給 collectionInfo，所以用 start-end
        grid-column-start: 7;
        grid-column-end: 13;
        list-style: none;
        margin: 0;
        padding: 0;
        &.collectionsContainer {
            display: grid;
            grid-template-columns: repeat(6, minmax(0, 1fr));
            gap: 16px;
            max-height: 300px;
            overflow: auto;
            @media screen and (min-width: 1000px) {
                // 平板橫式
                max-height: none;
            }
        }
        .collection {
            grid-column: span 2 / span 2;
            &.relative {
                position: relative;
            }
            img {
                max-width: 100%;
                object-fit: cover;
                &.opacity50 {
                    opacity: 0.5;
                }
            }
            div.blockEl {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }
    }
}

.heading {
    &:global(.MuiTypography-h4) {
        margin-top: 24px;
        text-align: center;
        color: $gray-200;
    }
}

.collectionsBox {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.collectionCard {
    &:global(.MuiCard-root) {
        width: 100%;
        display: flex;
        justify-content: space-between;

        background: transparent;
        box-shadow: none;
    }
}

.cardContent {
    &:global(.MuiCardContent-root) {
        width: 55%;
        padding: 0;

        color: $gray-200;
        &:last-child {
            // 因為 MUI Card 的 pb 是放在 last-child
            padding-bottom: 0;
        }
    }

    .item {
        padding: 0;
    }
}

.cardInfo {
    max-width: 100%;
    max-height: 300px;
    @media screen and (min-width: 1000px) {
        // 平板橫式
        max-height: none;
    }
}

// collection modal
.exchangeModal {
    @include absolutely-centered;
    width: 100%;
    text-align: center;
    background: $gray-100;
    padding: 32px;

    .text {
        color: $red;
        margin-top: 8px;
    }
}

.buttonsBox {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    gap: 16px;
}
